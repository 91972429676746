@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'GlacialIndifference';
    font-weight: bold;
    src: local('GlacialIndifference-Bold'), url('./fonts/GlacialIndifference/GlacialIndifference-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'GlacialIndifference';
    src: local('GlacialIndifference-Regular'), url('./fonts/GlacialIndifference/GlacialIndifference-Regular.otf') format('opentype');
}

html, body, #root {
    height: 100%;
    font-family: 'Montserrat', sans-serif;
}

.event-share-preview {
    zoom: 0.2;
    -moz-transform: scale(0.2);
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
  }
  
/* Hide scrollbar for IE, Edge and Firefox */
* {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/*colors*/
:root {
    --white: #ffffff;
    --black: #000000;
    --dark: #090909;
    --darkgrey: #222222;
    --grey: #4b4b4b;
    --lightgrey: #9b9b9b;
    --light: #e6e6e6;
    --blue: #0d6efd;
    --red: #f20000;
    --green: #4ab022;
    --orange: #faab17;
    --purple: #8b44fd;
    --yellow: #fdfd44;
  }
  
  
  /*fontsizes*/
  :root {
    --fontsize-small: 12px; /* 12px */
    --fontsize-regular: 14px; /* 14px */
    --fontsize-medium: 16px; /* 16px */
    --fontsize-large: 18px; /* 18px */
    --fontsize-big: 20px; /* 20px */
    --fontsize-huge: 22px; /* 22px */
    --fontsize-massive: 24px; /* 24px */
    --fontsize-gigantic: 26px; /* 26px */
    --fontsize-enormous: 30px; /* 30px */
    --fontsize-humongous: 36px; /* 36px */
    --fontsize-yourmum: 40px; /* 40px */
  }
  
  
  @media only screen and (max-height: 925px) {
    :root {
      --fontsize-small: 10px;
      --fontsize-regular: 12px; 
      --fontsize-medium: 14px; 
      --fontsize-large: 16px; 
      --fontsize-big: 18px; 
      --fontsize-huge: 20px; 
      --fontsize-massive: 22px; 
      --fontsize-gigantic: 24px; 
      --fontsize-enormous: 26px; 
      --fontsize-humongous: 34px; 
      --fontsize-yourmum: 38px; 
    }
  }
  
  
  @media only screen and (max-height: 880px) {
    :root {
      --fontsize-small: 9px;
      --fontsize-regular: 11px;
      --fontsize-medium: 13px;
      --fontsize-large: 15px;
      --fontsize-big: 17px;
      --fontsize-huge: 19px;
      --fontsize-massive: 21px;
      --fontsize-gigantic: 23px;
      --fontsize-enormous: 25px;
      --fontsize-humongous: 33px;
      --fontsize-yourmum: 37px;
    }
  }