.StatisticHeader{
    color: var(--black);
    font-size: var(--fontsize-medium);
}

.StatisticNumber{
    color: var(--black);
    font-size: var(--fontsize-humongous);
    font-weight: 600;
}

.BigStatisticHeader{
    font-weight: 600;
    font-size: var(--fontsize-large);
    color: var(--white);
}

.StatisticItemBox{
    background-color: var(--light);
    border-radius: 5px;
    padding: 20px;
    width: 32%;
    gap: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.StatisticItemBoxHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.StatisticItemBoxBig{
    background-color: var(--dark);
    border-radius: 5px;
    padding: 20px;
    width: 33%;
    height: 37vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DatePicker{
    font-weight: 600;
    color: var(--white);
    text-align: center;
}

.DatePickerContainer{
    color: var(--lightgrey);
    font-size: var(--fontsize-huge);
    width: 20%;
    justify-content: center;
    align-items: center;
    gap: 20px;
    display: flex;
    flex-direction: row;
}