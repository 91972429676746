/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    padding: 8px;
    margin-top: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--grey);
    --_m: 
      conic-gradient(#0000 10%,#000),
      linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
            mask: var(--_m);
    -webkit-mask-composite: source-out;
            mask-composite: subtract;
    animation: rotate 1s infinite linear;
    &_for_changelog_content{
       @extend .loader;
        position: absolute;
        top: 40%;
        left: 63%;
 }
}
  @keyframes rotate {to{transform: rotate(1turn)}}


@media screen and (max-width: 768px) {
        #submenu{
                left: 15rem !important;
        }
}