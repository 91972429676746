/* GENERAL - start */

.statsPlaceholderLine {
    color: var(--textMain);
    text-shadow: 1px 1px 2px var(--textThird);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    height: 100%;
    width: 90%;
    position: absolute; 
    z-index: 3; 
}

.statsPlaceholderBox {
    display: flex;
    flex-direction: column;
    position: relative; 
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

/* GENERAL - end */



/* PIE - start */

.statsPieBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 2rem;
}

.statsPieContainer{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.statsPieLabelBox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.statsPieLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.statsPieLabelNumber{
    font-size: var(--fontsize-humongous);
    font-weight: 600;
}
  
.statsPieLabelDescription{
    font-size: var(--fontsize-regular);
}

/* PIE - end */

.statsLineChart {
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}

/* tooltip */
.tooltipStatistics {
    background-color: var(--white);
    position: absolute;
    top: calc(100% + 5px);
    transform: translateX(-93%);
    width: 12.239583333333334vw;
    border-radius: 5px;
    z-index: 4;
}
  
.tooltipContainer {
    background-color: var(--white);
    color: var(--black);
    padding: 10px;
    font-size: var(--fontsize-regular);
    border-radius: 5px;
}
  
.tooltipHidden {
    position: relative;
}
  
.InfoIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--fontsize-medium);
    cursor: pointer;
    height: 25px;
    width: 25px;
    background-color: var(--white);
    color: var(--black);
    border-radius: 400px;
}

.PageHeaderBox {
    min-height: 100px;
    background-color: var(--dark);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 25px;
    margin-bottom: 10px;
    justify-content: space-between;
  }
  
  .PageHeaderBoxTitle{
    color: var(--white);
    font-weight: 600;
    font-size: var(--fontsize-humongous);
  }
  
  .PageHeaderBoxSubtitle {
    color: var(--lightgrey);
    font-size: var(--fontsize-medium);
  }
  
  .content {
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 20px 30px;
    scrollbar-width: none;
  }

  .statsSectionTitle{
    font-size: var(--fontsize-massive);
    margin-bottom: 5px;
  }

  .statsRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .newBox{
    display: flex;
    flex-direction: column;
  }